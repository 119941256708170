import React from 'react';
import classes from '../../FormBlock.module.scss';
import { ExistingCasesProps } from './ExistingCases.props';
import SingleCase from 'components/Preview/Cases/SingleCase';
import clsx from 'clsx';

const ExistingCases = ({
  handleSelectCases,
  existingCases,
}: ExistingCasesProps) => {
  return (
    <>
      <div
        className={clsx(
          classes.existingBlock,
          existingCases &&
            existingCases?.length > 3 &&
            classes.existingBlock__withScroll
        )}
      >
        {existingCases?.map((item: any) => (
          <SingleCase
            singleCase={item}
            key={item?.id}
            existingCaseBlock
            handleSelect={handleSelectCases}
            withScroll={existingCases?.length > 3}
          />
        ))}
      </div>
    </>
  );
};

export default ExistingCases;
