import classes from './OurTeam.module.scss';
import { decodeHtmlEntities } from 'tools/utils';

interface OurTeamProps {
  data: '';
  title: string;
}

export const OurTeam = ({ data, title }: OurTeamProps) => (
  <div className={classes.wrapper}>
    <div className={classes.title}>{title}</div>
    <div dangerouslySetInnerHTML={{ __html: decodeHtmlEntities(data) }} />
  </div>
);
