import { yupResolver } from '@hookform/resolvers/yup';
import { RegistrationStepTwoProps } from '@type/auth';
import { registrationRuStepOneWithInviteSchema, registrationStepTwoSchema } from 'containers/Account/validationSchemas';
import { useAuthStore } from 'contexts/AuthContext';
import { toJS } from 'mobx';
import { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { commonStore } from 'stores/commonStore';
import { getYmClientId } from 'tools/analytics';
import { Schema } from 'yup';

export const useRegistrationFormRu = () => {
  const authStore = useAuthStore();

  const kidUserInfo = toJS(authStore).kid_user_info as any;
  const isInvite = kidUserInfo && kidUserInfo?.invite

  const formRef = useRef(null);
  const navigate = useNavigate();
  const currentLanguage = localStorage.getItem('kitGlobalLng');
  const { t, i18n } = useTranslation();

  const [schema, setSchema] = useState<Schema>(
    isInvite
      ? registrationRuStepOneWithInviteSchema(t)
      : registrationStepTwoSchema(t)
  );
  const [isDisabled, setIsDisabled] = useState(false);


  useEffect(() => {
    setSchema(
      isInvite
        ? registrationRuStepOneWithInviteSchema(t)
        : registrationStepTwoSchema(t)
    );
  }, [currentLanguage, isInvite]);

  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
    setError,
    getValues,
    setValue,
  } = useForm<RegistrationStepTwoProps>({
    resolver: yupResolver(schema as any),
    mode: 'all',
  });

  const { jobTitle, captcha, userProducts, location, companyUrl, companyName } =
    getValues() as RegistrationStepTwoProps;

  const [checkErrors, setCheckErrors] = useState(false);
  const [agree, setAgree] = useState(false);

  const setErrorMessage = (field: any, message: string) => {
    setError(field, { type: 'server', message });
  };

  const onSubmit = async () => {
    const formData = new FormData();
    const ymClientId = getYmClientId();

    setCheckErrors(isValid);

    if (kidUserInfo) {
      formData.append('kid_token', kidUserInfo.token);
      formData.append(
        'full_name',
        `${kidUserInfo.first_name} ${kidUserInfo.last_name}`
      );
      formData.append('email', kidUserInfo.email);
      formData.append('phone_number', kidUserInfo.phone);
    }
    formData.append('language', i18n.language);

    if (!isInvite) {
      formData.append('company_name', companyName);
      formData.append('company_link', companyUrl);
      formData.append('user_role_in_company', jobTitle);
      formData.append('country', String(selectedLocation?.id));
      formData.append(
        'user_products',
        selectedProducts.map((product) => product?.id).toString()
      );
    }


    if (ymClientId) {
      formData.append('ym_client_id', ymClientId);
    }

    const utm_mark = localStorage.getItem('utm_mark');
    if (utm_mark) {
      formData.append('utm_mark', utm_mark);
    }

    authStore.register(
      formData,
      isInvite,
      () => {

        if (isInvite) {
          navigate('/account/personal', { replace: true });
        } else {
          window.location.replace(
            `${process.env.REACT_APP_KID_URL}?site_id=${process.env.REACT_APP_KID_SITE_ID}&3`
          );
        }
        
      },
      (response) => {
        const { form_errors } = response;

        const {
          company_name,
          company_link,
          user_products,
          user_role_in_company,
          country,
        } = form_errors;

        if (company_name) {
          setErrorMessage('companyName', company_name[0]);
        }

        if (company_link) {
          setErrorMessage('companyUrl', company_link[0]);
        }

        if (user_role_in_company) {
          setErrorMessage('jobTitle', user_role_in_company[0]);
        }

        if (user_products) {
          setErrorMessage('userProducts', user_products[0]);
        }

        if (country) {
          setErrorMessage('location', country[0]);
        }
      }
    );
  };

  const [selectedLocation, setSelectedLocation] = useState<{
    id: number;
    name: string;
  }>();

  const productsOptions = commonStore.products.map((product) => ({
    value: product.id,
    label: product.name,
  }));

  const [internalProducts, setInternalProducts] = useState<
    { value: number; label: string }[]
  >([]);

  useEffect(() => {
    setInternalProducts(
      commonStore.products.map((product) => ({
        value: product.id,
        label: product.name,
      }))
    );
  }, [commonStore.products]);

  useEffect(() => {
    commonStore.loadCountries({ all: true }); //добавить пагинацию при необходимости, на данный момент весь список стран приходит за ~188ms, а одна страница ~ за 90ms(поэтому смысла в пагинации нет)
  }, []);

  const locationOptions = commonStore.countries.map((country) => ({
    value: country.id,
    label: country.name,
  }));

  const [selectedProducts, setSelectedProducts] = useState<
    {
      id: number;
      name: React.ReactNode;
    }[]
  >([]);

  const isLoadingProducts = commonStore.loadProductsStatus.isLoading;

  const [productsPage, setProductsPage] = useState(1);

  const onLoadProducts = () => {
    if (commonStore.loadProductsStatus.data?.next) {
      setProductsPage((page) => page + 1);
    }
  };

  const callbacks = {
    onSelectProduct: (id: string | number) => {
      if (id === 'all') {
        setSelectedProducts(
          productsOptions.map((product) => ({
            id: product.value,
            name: product.label,
          }))
        );
        return;
      }

      const selectedProduct = productsOptions.find(
        (product) => product.value === id
      );
      if (selectedProduct) {
        setSelectedProducts((prevSelected) => [
          ...prevSelected,
          {
            id: selectedProduct.value,
            name: selectedProduct.label,
          },
        ]);
      }
    },
    onSelectLocation: (id: number) => {
      const selectedLocation = locationOptions.find(
        (location) => location.value === id
      );
      if (selectedLocation) {
        setSelectedLocation({
          id: selectedLocation.value,
          name: selectedLocation.label,
        });
      }
    },
    onDeleteSelectedProduct: (id: string | number) => {
      if (id === 'all') {
        setSelectedProducts([]);
        return;
      }

      setSelectedProducts((prevSelected) =>
        prevSelected.filter((product) => product.id !== id)
      );
    },
  };

  useEffect(() => {
    commonStore.loadCompanyProducts({ page: productsPage });
  }, [productsPage]);

  useEffect(() => {
    if (kidUserInfo) {
      setValue('kid_token', kidUserInfo.kid_token);
    } else {
      navigate('/login');
    }
  }, []);

  useEffect(() => {
    if (isInvite && agree) {
      setIsDisabled(true);
      return;
    }

    if (
      jobTitle !== '' &&
      selectedProducts.length &&
      selectedLocation &&
      companyUrl &&
      isValid &&
      agree
    ) {
      setIsDisabled(true);
      return
    }
  
    setIsDisabled(false);
  }, [jobTitle, selectedProducts, location, companyUrl, isValid, agree, isInvite]);

  return {
    formRef,
    handleSubmit,
    onSubmit,
    errors,
    t,
    control,
    selectedLocation,
    locationOptions,
    callbacks,
    selectedProducts,
    onLoadProducts,
    productsOptions,
    internalProducts,
    setInternalProducts,
    isLoadingProducts,
    i18n,
    isDisabled,
    setAgree,
    isInvite
  };
};
