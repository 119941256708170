import * as React from 'react';
import { SVGProps } from 'react';
import { RUSSIAN_LOCALE } from 'utils';

export const AdminPanelGradientIconActive = (
  props: SVGProps<SVGSVGElement>
) => {
  const gradientId = React.useId();

  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <defs>
        {RUSSIAN_LOCALE ? (
          <linearGradient id={`${gradientId}`} gradientTransform="rotate(150)">
            <stop offset="8.91%" stopColor="#e47267" />
            <stop offset="93.43%" stopColor="#eb5945" />
          </linearGradient>
        ) : (
          <linearGradient id={`${gradientId}`} gradientTransform="rotate(140)">
            <stop offset="0%" stopColor="#6e3cff" />
            <stop offset="100%" stopColor="#4ba5ff" />
          </linearGradient>
        )}
      </defs>

      <path
        d="M11 15C9.23892 15 7.52705 15.4358 6.1299 16.2399C4.73275 17.0439 3.72838 18.1713 3.27258 19.4471M15 7C15 9.20914 13.2091 11 11 11C8.79085 11 6.99999 9.20914 6.99999 7C6.99999 4.79086 8.79085 3 11 3C13.2091 3 15 4.79086 15 7ZM11 21L13.8802 20.1771C14.0181 20.1377 14.0871 20.118 14.1514 20.0884C14.2085 20.0622 14.2628 20.0303 14.3135 19.9931C14.3705 19.9512 14.4212 19.9005 14.5227 19.799L20.5191 13.8026C21.1603 13.1615 21.1603 12.122 20.5191 11.4808C19.878 10.8397 18.8385 10.8397 18.1974 11.4809L12.2009 17.4773C12.0995 17.5787 12.0488 17.6294 12.0069 17.6865C11.9697 17.7372 11.9378 17.7915 11.9115 17.8486C11.882 17.9129 11.8623 17.9819 11.8229 18.1198L11 21Z"
        stroke={`url(#${gradientId})`}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
