import { AxiosResponse } from 'axios';
import api from './http';

export default class Partner {
  static async getServices(
    partnerId: number,
    params: string
  ): Promise<AxiosResponse> {
    return api.get(`/api/v1/partners/${partnerId}/services?${params}`);
  }

  static async archiveService(slug: string): Promise<AxiosResponse> {
    return api.post(`api/v1/partners/services/${slug}/archive/`);
  }

  static async unarchiveService(slug: string): Promise<AxiosResponse> {
    return api.delete(`api/v1/partners/services/${slug}/archive/`);
  }

  static async getSingleService(slug: string): Promise<AxiosResponse> {
    return api.get(`/api/v1/partners/services/${slug}/`);
  }

  static async getSingleServiceAnalytics(
    params: string,
    slug: string
  ): Promise<AxiosResponse> {
    const paramsData = params ? `?${params}` : '';
    return api.get(`/api/v1/partners/services/${slug}/analytics/${paramsData}`);
  }

  static async getSolutionGoals(): Promise<AxiosResponse> {
    return api.get('/api/v1/goals/list/');
  }

  static async createSolution(data: any): Promise<AxiosResponse> {
    return api.post('/api/v1/partners/services/create/', data);
  }

  //методы из старого user service, возможно они уже не нужны

  static async getProjectOld(projectId: number): Promise<AxiosResponse> {
    return api
      .get(`api/v1/projects/${projectId}/`)
      .then((response) => response);
  }

  static async updateProject(projectId: number, goal_name: string) {
    return await api
      .post(`api/v1/projects/${projectId}/project_goal/`, {
        goal: { name: goal_name },
        project: projectId,
      })
      .then((response) => {
        if (response.status === 201) {
          return response?.data;
        }
      });
  }

  static async deleteGoal(projectId: number, goalId: number) {
    return await api
      .put(`api/v1/projects/${projectId}/project_goal/${goalId}/`)
      .then((response) => {
        if (response.status === 200) {
          return response?.data;
        }
      });
  }

  static async getCases(partnerId?: number) {
    return await api.get(`api/v1/partners/${partnerId}/cases/`);
  }
}
