import classes from './InvoicesTable/InvoicesTable.module.scss';
import { QueryParamTypes } from 'types/global';
import { formatPrice } from 'components/shared/FormatPriceAndCurrency';
import { TFunction } from 'i18next';
import { TFinancesResponse, TInvoice } from '@type/finance';
import { getFormattedDate, getFormattedTime } from 'tools/formattedDate';

export const getPaymentDateMessage = (
  invoice: TInvoice,
  paidDate: Date,
  diffDays: TInvoice['diff_days'],
  t: TFunction
) => {
  if (invoice.status === 'PAID' && paidDate) {
    return t('Paid {{ dateString }}', {
      dateString: getFormattedDate(paidDate),
    });
  } else if (invoice.status === 'PARTIALLY_PAID' && paidDate) {
    return t('Partially paid {{ dateString }}', {
      dateString: getFormattedDate(paidDate),
    });
  } else if (!diffDays?.overdue && diffDays?.difference) {
    return t('{{ dateString }} days for payment', {
      dateString: diffDays?.difference,
    });
  } else if (diffDays?.overdue) {
    return t('Overdue by {{ dateString }} days', {
      dateString: diffDays?.difference,
    });
  } else if (!diffDays?.overdue && !diffDays?.difference) {
    return t('Due today');
  }
};

export const columnsArray = (t: TFunction, incoming: boolean) => {
  const columns = [
    {
      header: '',
      accessor: 'serialNumber',
      footer: (props: { column: { id: string | number } }) => props.column.id,
    },
    {
      header: t('Invoices'),
      accessor: 'invoices',
      footer: (props: { column: { id: string | number } }) => props.column.id,
    },
    {
      header: t('Client'),
      accessor: 'client',
      footer: (props: { column: { id: string | number } }) => props.column.id,
    },
    {
      header: t('Issued'),
      accessor: 'issued',
      footer: (props: { column: { id: string | number } }) => props.column.id,
    },
    {
      header: t('Amount'),
      accessor: 'amount',
      footer: (props: { column: { id: string | number } }) => props.column.id,
    },
    {
      header: t('Action'),
      accessor: 'action',
      footer: (props: { column: { id: string | number } }) => props.column.id,
    },
  ];
  return incoming
    ? columns.filter((column) => column.accessor !== 'client')
    : columns;
};

export const tableData = (invoices: TInvoice[], t: TFunction) =>
  invoices?.map((invoice) => {
    const invoiceDate = new Date(invoice?.created);
    const paidDate = invoice.paid_date ? new Date(invoice.paid_date) : null;
    const diffDays = invoice.diff_days;
    const invoiceTotalAmount = formatPrice(invoice?.total);

    return {
      serialNumber: {
        value: invoice.serial_number,
        className: classes.table__header,
      },
      invoices: {
        value: invoice.invoice?.number,
        className: classes.table__header,
        filePath: invoice.file,
        description: {
          text: invoice.invoice?.description,
          className: classes.table__text,
        },
      },
      client: {
        value: invoice.client?.name,
        className: classes.table__header,
        description: {
          text: `ID ${invoice.client?.number}`,
          className: classes.table__text,
        },
      },
      issued: {
        value: getFormattedDate(invoiceDate),
        className: classes.table__header,
        description: {
          text: getFormattedTime(invoiceDate),
          className: classes.table__text,
        },
      },
      amount: {
        value: invoiceTotalAmount,
        currency: invoice.currency,
        className: classes.table__header,
        description: {
          text: getPaymentDateMessage(
            invoice,
            paidDate || new Date(),
            diffDays,
            t
          ),
          className: classes.table__text,
        },
      },
      action: {
        button: {
          text: invoice.status === 'PAID' ? t('Paid') : t('Pay now'),
          disabled: invoice.status === 'PAID',
          className: classes.table__button,
          filePath: invoice.file,
        },
      },
    };
  });

export const queryParamsHandler = ({
  page,
  pageSize,
  quickFilter,
}: QueryParamTypes) => {
  const queryParams = {
    page,
    page_size: pageSize,
    'quick-filter': quickFilter,
  };
  const params = new URLSearchParams();

  Object.entries(queryParams).forEach(([key, value]) => {
    if (value) {
      params.append(key, String(value));
    }
  });
  return params.toString();
};
