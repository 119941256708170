import clsx from 'classnames';
import { Fader } from 'components/shared/Fader';
import { Pagination } from 'components/shared/Pagination';
import _, { debounce } from 'lodash';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { usePagination, useSortBy, useTable } from 'react-table';
import classes from './InvoicesTable.module.scss';
import { TableProps } from './InvoicesTable.props';
import GetColumnFilter from './GetColumnFilter/GetColumnFilter';
import MainContent from './MainContent/MainContent';

function InvoicesTable({
  data,
  columns,
  tableActive,
  handleGlobalSearch,
  handleSort,
  handleFilterAction,
  handlePeriodChange,
  getDataByPage,
  id,
  additionalClassNames,
  incomeMode,
  handlePaymentClick,
}: TableProps) {
  const { t } = useTranslation();
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
    page,
    canPreviousPage,
    canNextPage,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    state: { pageIndex },
  } = useTable(
    { columns, data, initialState: { pageSize: 8 } },
    useSortBy,
    usePagination
  );

  const actionFilterOptions = [
    { value: 'paid', label: t('Paid') },
    { value: 'not_paid', label: t('Pay now') },
  ];

  const [currentPage, setCurrentPage] = useState(1);
  const [openedSort, setOpenedSort] = useState('');
  const [startPeriod, setStartPeriod] = useState<Date | null>(null);
  const [endPeriod, setEndPeriod] = useState<Date | null>(null);
  const [checkedActions, setCheckedActions] = useState<string[]>([]);
  const [globalSearch, setGlobalSearch] = useState('');
  const [invoicesContains, setInvoicesContains] = useState('');
  const [clientsContains, setClientsContains] = useState('');

  const callbacks = {
    onOpenSortings: (columnId: string) =>
      setOpenedSort((prev) => (prev === columnId ? '' : columnId)),
    onSort: (columnId: string, direction: 'asc' | 'desc') => {
      let currSort = '';

      switch (columnId) {
        case 'invoices':
          currSort = `number_${direction}`;
          break;
        case 'issued':
          currSort = `date_${direction}`;
          break;
        case 'amount':
        case 'client':
          currSort = `${columnId}_${direction}`;
          break;
        default:
          break;
      }

      setOpenedSort('');
      handleSort?.(currSort);
    },
    onPeriodChange: ([newStart, newEnd]: [Date | null, Date | null]) => {
      setStartPeriod(newStart);
      setEndPeriod(newEnd);
    },
    onSearch: (value: string) => {
      setGlobalSearch(value);
      debounce(() => handleGlobalSearch?.(value), 1000)();
    },
    onContains: (columnId: 'client' | 'invoices', value: string) => {
      if (columnId === 'client') {
        setClientsContains(value);
        setInvoicesContains('');
      } else if (columnId === 'invoices') {
        setInvoicesContains(value);
        setClientsContains('');
      }
    },
  };

  useEffect(() => {
    getDataByPage?.(currentPage);
  }, [currentPage]);

  useEffect(() => {
    handlePeriodChange?.(startPeriod, endPeriod);
  }, [endPeriod]);

  useEffect(() => {
    if (checkedActions.length === actionFilterOptions.length) {
      handleFilterAction?.('all');
    } else {
      handleFilterAction?.(checkedActions.toString());
    }
  }, [checkedActions, actionFilterOptions]);

  return (
    <>
      <Fader active={tableActive as boolean}>
        <div
          className={clsx(
            classes.table,
            incomeMode && classes.income,
            additionalClassNames && String(additionalClassNames)
          )}
          id={id}
        >
          <table style={{ width: '100%' }} {...getTableProps()}>
            <thead>
              {headerGroups?.map((headerGroup) => (
                <tr
                  {...headerGroup.getHeaderGroupProps()}
                  className={classes.table__row}
                >
                  <th className={classes.table__cell}>
                    <GetColumnFilter
                      columnId={'search'}
                      globalSearch={globalSearch}
                      openedSort={openedSort}
                      callbacks={callbacks}
                      setOpenedSort={setOpenedSort}
                      invoicesContains={invoicesContains}
                      clientsContains={clientsContains}
                      startPeriod={startPeriod}
                      endPeriod={endPeriod}
                      setCheckedActions={setCheckedActions}
                      checkedActions={checkedActions}
                      actionFilterOptions={actionFilterOptions}
                    />
                  </th>
                  {headerGroup?.headers?.map((column: any) => {
                    if (column?.header) {
                      return (
                        <th
                          className={classes.table__cell}
                          {...column.getHeaderProps()}
                        >
                          {column.render('header')}
                          <GetColumnFilter
                            columnId={column.id}
                            globalSearch={globalSearch}
                            openedSort={openedSort}
                            callbacks={callbacks}
                            setOpenedSort={setOpenedSort}
                            invoicesContains={invoicesContains}
                            clientsContains={clientsContains}
                            startPeriod={startPeriod}
                            endPeriod={endPeriod}
                            setCheckedActions={setCheckedActions}
                            checkedActions={checkedActions}
                            actionFilterOptions={actionFilterOptions}
                          />
                        </th>
                      );
                    } else return null;
                  })}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {page.map((row) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()} className={classes.table__content}>
                    {row?.cells?.map((cell) => {
                      const { value } = cell;
                      return (
                        <td
                          {...cell.getCellProps()}
                          className={classes.table__cell}
                        >
                          <div className={classes.table__cell__container}>
                            <div className={classes.table__header}>
                              {value?.currency?.symbol && (
                                <span className={classes.currency}>
                                  {value?.currency?.symbol}
                                </span>
                              )}
                              <MainContent value={value?.value} />
                            </div>
                            <div className={value?.description?.className}>
                              {value?.description?.text}
                            </div>
                            {value?.button && (
                              <button
                                className={clsx(
                                  classes.table__button,
                                  value?.button?.className?.length &&
                                    String(value?.button?.className)
                                )}
                                disabled={value?.button?.disabled}
                                onClick={handlePaymentClick}
                              >
                                {value?.button?.text}
                              </button>
                            )}
                          </div>
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </Fader>

      {pageCount > 1 && (
        <div className={classes.pagination}>
          <Pagination
            currentPage={pageIndex + 1}
            totalPages={pageCount}
            setPageNumber={(page) => gotoPage(page - 1)}
            nextPage={canNextPage ? nextPage : undefined}
            prevPage={canPreviousPage ? previousPage : undefined}
          />
        </div>
      )}
    </>
  );
}

export default InvoicesTable;
