import { MultiSelectOption } from 'components/shared/MultiSelect';
import { useWindowWidth } from 'hooks/useWindowWidth';
import { MutableRefObject, useEffect, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { onDelete, onSelect } from '../../utils';
import classes from '../FormBlock.module.scss';
import { FormValidationProps } from '../FormBlock.props';
import { MultiSelectProps } from './FirsStep.props';
import { usePartnerStore } from 'contexts/PartnerContext';

export const useFirstStep = (
  companySizes: MultiSelectOption[],
  industryTypes: MultiSelectOption[],
  setCurrentStep?: (step: number) => void,
  disabled?: boolean,
  serviceTypes?: MultiSelectOption[],
  currentStep?: number,
  fieldRefs?: MutableRefObject<Record<string, HTMLDivElement | null>>
) => {
  const { isMediaTablet, isSmallLaptop: isLaptop } = useWindowWidth();
  const { isCompletedFormSteps, copiedService, setCopyOfService } =
    usePartnerStore();

  const name = useWatch({ name: 'name' });
  const worksFor = useWatch({ name: 'worksFor' });
  const productType = useWatch({ name: 'productType' });
  const industry = useWatch({ name: 'industry' });
  const goals = useWatch({ name: 'goals' });
  const shortDescriptionValue = useWatch({ name: 'shortDescription' });
  const fullDescriptionValue = useWatch({ name: 'fullDescription' });
  const projectDurationValue = useWatch({ name: 'projectDuration' });
  const steps = useWatch({ name: 'steps' });
  const defaultCompanySizes = useWatch({ name: 'companySize' });
  const defaultServiceTypes = useWatch({ name: 'serviceTypes' });

  const [selectIndustryTypes, setSelectIndustryTypes] = useState<
    MultiSelectProps[]
  >(industry || []);
  const [selectedCompanySizes, setSelectedCompanySizes] = useState(
    defaultCompanySizes || []
  );
  const [selectServiceTypes, setSelectServiceTypes] = useState<
    MultiSelectProps[]
  >(defaultServiceTypes || []);
  const [internalCompanySizes, setInternalCompanySizes] = useState<
    MultiSelectOption[]
  >([]);
  const [internalIndustries, setInternalIndustries] = useState<
    MultiSelectOption[]
  >([]);
  const [internalServiceTypes, setInternalServiceTypes] = useState<
    MultiSelectOption[]
  >([]);
  const [generalCount, setGeneralCount] = useState<number>(0);
  const [detailCount, setDetailCount] = useState<number>(0);
  const [materialCount, setMaterialCount] = useState<number>(0);

  const file = useWatch({ name: 'useCases-2' });

  const {
    trigger,
    getValues,
    setValue,
    formState: { errors },
    clearErrors,
    control,
  } = useFormContext<FormValidationProps>();

  useEffect(() => {
    setGeneralCount(
      [
        name,
        worksFor,
        productType,
        industry,
        defaultCompanySizes,
        goals?.length,
        defaultServiceTypes,
      ].filter((item) => item).length
    );
  }, [
    name,
    worksFor,
    defaultServiceTypes,
    productType,
    industry,
    defaultCompanySizes,
    goals,
  ]);

  useEffect(() => {
    setDetailCount(
      [
        shortDescriptionValue,
        fullDescriptionValue,
        projectDurationValue,
      ].filter((item) => item).length
    );
  }, [shortDescriptionValue, fullDescriptionValue, projectDurationValue]);

  useEffect(() => {
    setMaterialCount([file?.name].filter((item) => item)?.length);
    if (fullDescriptionValue === '') {
      setValue('fullDescription', '<p></p>');
    }
  }, []);

  useEffect(() => {
    companySizes && setInternalCompanySizes(companySizes);
  }, [companySizes]);

  useEffect(() => {
    industryTypes && setInternalIndustries(industryTypes);
  }, [industryTypes]);

  useEffect(() => {
    serviceTypes && setInternalServiceTypes(serviceTypes);
  }, [serviceTypes]);

  const endIconOptions = (title: string) => ({
    endIconName: 'HelpIcon',
    endIconTooltipText: title,
    endIconTooltipPosition: isMediaTablet ? 'top' : 'right',
    endIconTooltipClassName: classes.endIconTooltip,
    endIconClassName: classes.endIcon,
  });

  const errorsKeys = Object.keys(errors);

  useEffect(() => {
    if (currentStep === 1) {
      if (errorsKeys?.length > 0) {
        fieldRefs?.current?.[errorsKeys[0]]?.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        });
      }
    }
  }, [isCompletedFormSteps.stepOne, fieldRefs, errors]);

  const onValidateClick = async () => {
    setCopyOfService({ ...copiedService, ...getValues() });
    if (trigger) {
      const isValid = await trigger();

      if (isValid && setCurrentStep) {
        setCurrentStep(2);
        isCompletedFormSteps.stepOne = true;
        return window?.scrollTo({ top: 0, behavior: 'smooth' });
      }
    }
  };

  const callbacks = {
    onSelectIndustry: (id: number | string, onChange: any) =>
      onSelect(id, industryTypes, setSelectIndustryTypes, onChange),
    onDeleteSelectedIndustry: (id: number | string, onChange: any) =>
      onDelete(id, setSelectIndustryTypes, onChange),
    onSelectCompanySizes: (id: number | string, onChange: any) =>
      onSelect(id, companySizes, setSelectedCompanySizes, onChange),
    onDeleteCompanySize: (id: number | string, onChange: any) =>
      onDelete(id, setSelectedCompanySizes, onChange),
    onSelectServiceType: (id: number | string, onChange: any) =>
      onSelect(id, serviceTypes, setSelectServiceTypes, onChange),
    onDeleteServiceType: (id: number | string, onChange: any) =>
      onDelete(id, setSelectServiceTypes, onChange),
  };

  return {
    isLaptop,
    generalCount,
    endIconOptions,
    errors,
    control,
    internalIndustries,
    isMediaTablet,
    selectIndustryTypes,
    callbacks,
    setInternalIndustries,
    selectedCompanySizes,
    internalCompanySizes,
    setInternalCompanySizes,
    detailCount,
    materialCount,
    onValidateClick,
    file,
    setMaterialCount,
    internalServiceTypes,
    setInternalServiceTypes,
    selectServiceTypes,
    setSelectServiceTypes,
    clearErrors,
  };
};
