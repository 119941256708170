import clsx from 'clsx';
import { Link } from 'components/shared/Link';
import { getFormattedDate } from 'tools/formattedDate';
import classes from './DocumentsList.module.scss';
import { DocumentsListProps } from './DocumentsList.props';
import ProjectsService from 'services/projects';
import { useTranslation } from 'react-i18next';

function DocumentsList({ documents, additionalClass }: DocumentsListProps) {
  const { t } = useTranslation();
  const fileName = (name: string) =>
    name.length > 25 ? `${name?.slice(0, 25)}...` : name;

  const newFileName = (name: string) => {
    if (name.includes('agreement')) {
      return t('Partner Agreement');
    }
    if (name.includes('nda')) {
      return t('NDA');
    }
  };

  return (
    <ul className={clsx(classes.documents, additionalClass)}>
      {documents.map((doc) => (
        <li key={doc.id} className={classes.documents__item}>
          <Link
            href="#"
            className={classes.documents__title}
            onClick={async () =>
              await ProjectsService.handleDownloadFile(
                doc?.link,
                doc?.name as string
              )
            }
          >
            {/*{fileName(doc?.name)} TODO: remove comment when fileNames are ready */}
            {newFileName(doc.link)}
          </Link>

          <span className={classes.documents__size}>
            {(doc.size / 1024 / 1024).toFixed(2)}Mb
          </span>

          <div className={classes.dashed} />

          <span className={classes.documents__date}>
            {getFormattedDate(new Date(doc.date))}
          </span>
        </li>
      ))}
    </ul>
  );
}

export default DocumentsList;
