import { TInvoice } from '@type/finance';
import PageTabs from 'components/common/PageTabs';
import EmptyProducts from 'components/shared/EmptyProducts/EmptyProducts';
import { TitleAndDescription } from 'components/shared/TitleAndDescription/TitleAndDescription';
import { LABELS } from 'constants/onboarding';
import { Steps } from 'intro.js-react';
import { observer } from 'mobx-react-lite';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import {
  completeOnboarding,
  findOnboardingSteps,
  formatSteps,
  OnboardingSteps,
} from 'tools/onboarding';
import { useWindowWidth } from '../../hooks/useWindowWidth';
import 'styles/helpers/introTooltip.scss';
import classes from './Finances.module.scss';
import DocumentsCard from './InfoCards/DocumentsCard';
import LegalEntityCard from './InfoCards/LegalEntityCard';
import TotalCard from './InfoCards/TotalCard';
import InvoicesList from './InvoicesList';
import InvoicesTable from './InvoicesTable';
import PaymentModal from './PaymentModal';
import { columnsArray } from './invoiceData';
import { financesStore } from '../../stores/financesStore';
import { RUSSIAN_LOCALE } from '../../utils';

type FinancesTabs = {
  id: 'in' | 'out';
  label: React.ReactNode;
};

export const FinancesContainer = observer(() => {
  const { t } = useTranslation();
  const { GOT_IT, NEXT, BACK } = LABELS(t);

  const { finances } = financesStore || {};

  const documents = finances?.files
    ?.map((file, index) => {
      return { ...file, id: index };
    })
    .sort((a, b) => b.id - a.id);

  const tabs: FinancesTabs[] = [
    {
      id: 'in',
      label: (
        <p className={classes.tab}>
          {t('Incoming')}
          {<span>{finances?.results.count_detailed?.incoming}</span>}
        </p>
      ),
    },
    {
      id: 'out',
      label: (
        <p className={classes.tab}>
          {t('Outcoming')}{' '}
          {<span>{finances?.results.count_detailed?.outcoming}</span>}
        </p>
      ),
    },
  ];

  const [activeTab, setActiveTab] = useState<FinancesTabs['id']>('in');
  const [tableActive, setTableActive] = useState(false);
  const [customPageCount, setCustomPageCount] = useState<number | undefined>(5);

  const [openPayModal, setOpenPayModal] = useState(false);

  const columns = useMemo(
    () => columnsArray(t, activeTab === 'in'),
    [t, activeTab]
  );

  const invoices: TInvoice[] =
    (activeTab === 'in'
      ? finances?.results?.sub_invoices?.filter((invoice) => !invoice.client)
      : finances?.results?.sub_invoices?.filter((invoice) => invoice.client)) ||
    [];

  const isMobile = useWindowWidth().isMediaTablet;
  const [onboarding, setOnboarding] = useState<OnboardingSteps | null>(null);
  const onboardingPath = 'FINANCES_P';

  const hasCompletedOnboarding = useRef(false);

  useEffect(() => {
    findOnboardingSteps(onboardingPath).then((res) => setOnboarding(res));
  }, []);

  const completeCurrentOnboarding = useCallback(() => {
    if (hasCompletedOnboarding.current) {
      return;
    }

    completeOnboarding(onboarding?.id);
    setOnboarding(null);
    hasCompletedOnboarding.current = true;
  }, [onboarding?.id]);

  return (
    <>
      {!isMobile && onboarding ? (
        <Steps
          enabled={true}
          steps={formatSteps(onboardingPath, onboarding.steps)}
          options={{
            tooltipClass: 'customTooltip',
            showBullets: true,
            doneLabel: GOT_IT,
            nextLabel: NEXT,
            prevLabel: BACK,
          }}
          initialStep={0}
          onExit={completeCurrentOnboarding}
        />
      ) : null}

      <TitleAndDescription pageName="Finances" />

      <div className={classes.wrapper}>
        <div className={classes.title}>
          {t('Finances')}
          <span>{(!RUSSIAN_LOCALE && finances?.count) || 0}</span>
        </div>

        <div className={classes['content-wrapper']} data-step-finances_p-0>
          <div className={classes.flex}>
            <PageTabs
              activeTab={activeTab}
              tabs={tabs}
              onChange={(tabId) => setActiveTab(tabId)}
            />
            {/*{activeTab === 'in' && (
              <Button className={classes.btn} theme="primary">
                {t('Generate invoice')}
              </Button>
            )}*/}
          </div>

          <div className={classes['info-wrapper']}>
            <div className={classes['info-wrapper__legalEntity']}>
              <LegalEntityCard
                title={t('Legal entity')}
                companyName={(!RUSSIAN_LOCALE && finances?.legal_entity) || ''}
              />

              {!RUSSIAN_LOCALE && (
                <LegalEntityCard
                  title={t('KIT legal entity')}
                  companyName={
                    (!RUSSIAN_LOCALE && finances?.kit_legal_entity_id) || ''
                  }
                />
              )}
            </div>

            <TotalCard
              totalDue={
                (!RUSSIAN_LOCALE && finances?.total_due?.[0]?.sum_in_rub) || 0
              }
              invoicesCount={
                (!RUSSIAN_LOCALE && finances?.total_due?.[0]?.count) || 0
              }
              onPaymentClick={() => setOpenPayModal(true)}
            />

            <DocumentsCard
              documents={(!RUSSIAN_LOCALE && documents) || []}
              isMobile={isMobile}
            />
          </div>

          <div className={classes['invoices-wrapper']}>
            {invoices?.length > 0 && !RUSSIAN_LOCALE ? (
              isMobile ? (
                <InvoicesList
                  // invoices={invoices.slice(0, 5)}
                  invoices={[]}
                  pageCount={customPageCount || 0}
                  handlePaymentClick={() => setOpenPayModal(true)}
                  handleGlobalSearch={(val) => console.log(val)}
                  handleFilterAction={(val) => console.log(val)}
                  handlePeriodChange={(start, end) => console.log(start, end)}
                  handleSort={(val) => console.log(val)}
                />
              ) : (
                <InvoicesTable
                  // data={tableData(invoices, t)}
                  data={[]}
                  columns={columns}
                  tableActive={tableActive}
                  pageCount={customPageCount || 0}
                  getDataByPage={() => {}}
                  incomeMode={activeTab === 'in'}
                  handlePaymentClick={() => setOpenPayModal(true)}
                  handleGlobalSearch={(val) => console.log(val)}
                  handleFilterAction={(val) => console.log(val)}
                  handlePeriodChange={(start, end) => console.log(start, end)}
                  handleSort={(val) => console.log(val)}
                />
              )
            ) : (
              <div className={classes.empty}>
                <EmptyProducts
                  additionalClass={classes.empty__content}
                  bottomText={t("You don't have any invoices")}
                />
              </div>
            )}
          </div>
        </div>
      </div>

      <PaymentModal
        title="You have invoice for $120 000"
        isMobile={isMobile}
        open={openPayModal}
        infoTitle={t('Bank transfer')}
        infoDesc={t('Pay from your bank account using details in the invoice')}
        infoDownloadText={t('Download invoice')}
        onClose={() => setOpenPayModal(false)}
      />
    </>
  );
});
