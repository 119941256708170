import { PARTNER_ROLE } from 'types/user';
import { OptionTypeString } from 'types/util-types';
import i18next from 'i18next';

export const getEmployeesRoles = (): OptionTypeString[] => [
  {
    id: PARTNER_ROLE.ADMIN,
    name: i18next.t('Admin'),
    description: i18next.t(
      'All platform sections are available, including employee invitations and access level management'
    ),
  },
  {
    id: PARTNER_ROLE.MANAGER,
    name: i18next.t('Manager'),
    description: i18next.t(
      'Access to viewing and ordering Solutions, managing Projects, and viewing Dashboards is available. No access to managing Finances or inviting employees'
    ),
  },
  {
    id: PARTNER_ROLE.VIEWER,
    name: i18next.t('Viewer'),
    description: i18next.t(
      'Only viewing of Solutions, Projects, and Dashboards is available'
    ),
  },
];
