import { FileIcon } from 'components/shared/Icons/FileIcon';
import classes from './ProjectDocuments.module.scss';
import { TFunction } from 'i18next';
import { handleDownload } from 'tools/fileDownload';
import { Project } from '@type/projects';
import ProjectsService from '../../../services/projects';
import { CommercialIcon } from 'components/shared/Icons/CommercialIcon';
import React from 'react';

type ProjectDocumentsProps = {
  t: TFunction;
  project: Project | null;
};

function ProjectDocuments({ project, t }: ProjectDocumentsProps) {
    return (
      <div className={classes['documents-container']}>
        <ul className={classes['documents-list']}>
          {project?.files.map((doc, index) => {
            return (
              <li
                key={index + doc.file + doc.size}
                className={classes['documents-list__item']}
              >
                <div className={classes['documents-list__item_icon']}>
                  <FileIcon width={14} height={14} viewBox="0 0 14 14" />
                </div>
                <div className={classes['documents-list__item_info']}>
                  <div className={classes['documents-list__item_header']}>
                    <span
                      className={classes['documents-list__item_label']}
                      onClick={async () =>
                        await ProjectsService.handleDownloadFile(
                          doc?.file,
                          (doc.file_name || doc.file.split('/').pop()) as string
                        )
                      }
                    >
                      {doc?.file_name || doc.file.split('/').at(-1)}
                    </span>
                    <span className={classes['documents-list__item_size']}>
                      {(doc.size / 1024 / 1024).toFixed(3) + t('Mb')}
                    </span>
                  </div>
                  <span className={classes['documents-list__item_desc']}>
                    {doc.description || t('file')}
                  </span>
                </div>
              </li>
            );
          })}
        </ul>

        {project?.agreement_file && (
          <ul className={classes['documents-list']}>
            <li
              className={`${classes['documents-list__item']} 
              ${!project.agreement_description && classes['documents-list__item_center']}`}
            >
              <div className={classes['documents-list__item_icon']}>
                <FileIcon width={14} height={14} viewBox="0 0 14 14"/>
              </div>
              <div className={classes['documents-list__item_info']}>
                <div className={classes['documents-list__item_header']}>
                  <span
                    className={classes['documents-list__item_label']}
                    onClick={async () => {
                      if (project.agreement_file) {
                        const fileName = project.agreement_name || project.agreement_file.split('/').pop();
                        if (fileName) {
                          await ProjectsService.handleDownloadFile(project.agreement_file, fileName);
                        }
                      }
                    }}
                  >
                    {project.agreement_name ||
                      project.agreement_file.split('/').pop()}
                    <span className={classes['documents-list__item_size']}>
                      {project.agreement_file_size}
                    </span>
                  </span>
                </div>
                {project.agreement_description && (
                  <span className={classes['documents-list__item_desc']}>
                    {project.agreement_description}
                  </span>
                )}
              </div>
            </li>
          </ul>
        )}

  {project?.commercial_proposal_file && (
          <ul className={classes['documents-list']}>
            <li
              className={`${classes['documents-list__item']} 
              ${!project.commercial_proposal_description && classes['documents-list__item_center']}`}
            >
              <div className={classes['documents-list__item_icon']}>
                <CommercialIcon />
              </div>
              <div className={classes['documents-list__item_info']}>
                <div className={classes['documents-list__item_header']}>
                  <span
                    className={classes['documents-list__item_label']}
                    onClick={async () => {
                      if (project.commercial_proposal_file) {
                        const fileName = project.commercial_proposal_name || project.commercial_proposal_file.split('/').pop();
                        if (fileName) {
                          await ProjectsService.handleDownloadFile(project.commercial_proposal_file, fileName);
                        }
                      }
                    }}
                  >
                    {project.commercial_proposal_name ||
                      project.commercial_proposal_file.split('/').pop()}
                    <span className={classes['documents-list__item_size']}>
                      {project.commercial_proposal_file_size}
                    </span>
                  </span>
                </div>
                {project.commercial_proposal_description && (
                  <span className={classes['documents-list__item_desc']}>
                    {project.commercial_proposal_description}
                  </span>
                )}
              </div>
            </li>
          </ul>
        )}
      </div>
    );
}


export default ProjectDocuments;
