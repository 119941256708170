import { DotsIcon } from 'components/shared/Icons/DotsIcon';
import classes from './MenuCell.module.scss';
import FilterSearch from 'components/shared/FilterSearch/FilterSearch';
import { useWindowWidth } from 'hooks/useWindowWidth';
import React, { useMemo, useState } from 'react';
import { ModalMobile } from 'components/shared/ModalMobile';
import { MenuCellProps } from './MenuCell.props';
import { useTranslation } from 'react-i18next';
import { Button } from 'components/shared/Button';
import { getEmployeesRoles } from '../../employeesRoles';
import { OptionTypeString } from 'types/util-types';
import { Radio } from 'components/shared/Radio';
import { PARTNER_ROLE } from 'types/user';
import clsx from 'clsx';

const MenuCell = ({
  value,
  handleDeletePermissions,
  handleChangePermissions,
  isDisabledChangeRole,
}: MenuCellProps) => {
  const { isMediaTablet } = useWindowWidth();
  const { t, i18n } = useTranslation();

  const employeesRoles = useMemo(() => getEmployeesRoles(), [i18n.language])


  const [isShowMenuModal, setIsShowMenuModal] = useState(false);
  const handleShowMenuModal = () => {
    setIsShowMenuModal(true);
  };
  const handleCloseMenuModal = () => {
    setIsShowMenuModal(false);
  };

  const [isShowDotsMenu, setShowDotsMenu] = useState(false);
  const handleShowDotsModal = () => {
    setShowDotsMenu(true);
  };
  const handleCloseDotsModal = () => {
    setShowDotsMenu(false);
  };

  const handleClose = () => {
    handleCloseDotsModal();
    handleCloseMenuModal();
    close();
  };

  const [typeUser, setTypeUser] = useState<PARTNER_ROLE>(value.permission.type);
  return (
    <>
      <div className={classes.dots}>
        {isMediaTablet ? (
          <DotsIcon onClick={() => handleShowMenuModal()} />
        ) : (
          <FilterSearch
            classNameForButton={classes.table__search}
            buttonElement={<DotsIcon />}
            isLastFilter={true}
          >
            {() => (
              <div onClick={handleShowDotsModal} className={classes.delete}>
                {t('Delete')}
              </div>
            )}
          </FilterSearch>
        )}
      </div>

      <ModalMobile
        title=""
        isOpen={isShowMenuModal}
        onClose={handleCloseMenuModal}
        isBasicHeader={false}
        crossIcon
      >
        <div className={classes.inner}>
          <div className={classes.headerWrapper}>
            <h3
              className={clsx(
                classes.title,
                !value.id && classes.titleWithoutId
              )}
            >
              {value.full_name || value.email}
            </h3>
            {value.id && <h3 className={classes.titleEmail}><a>{value.email}</a></h3>}

            <div className={classes.radioGroup}>
              {employeesRoles.map((role: OptionTypeString) => (
                <Radio
                  key={role.id}
                  name="role"
                  value={role}
                  checked={typeUser === role.id}
                  onChange={(item) => setTypeUser(item?.id as PARTNER_ROLE)}
                  label={role.name ? t(role.name) : ''}
                  description={role.description ? t(role.description) : ''}
                  disabled={isDisabledChangeRole}
                />
              ))}
            </div>
          </div>

          <div className={classes.buttons}>
            <Button
              className={classes.button}
              theme="primary"
              size="middle"
              onClick={async (e) => {
                e.stopPropagation();
                if (typeUser !== value.permission.type)
                  await handleChangePermissions(
                    value.id,
                    typeUser,
                    value.email
                  );

                handleClose();
              }}
              disabled={isDisabledChangeRole}
            >
              {t('Save changes')}
            </Button>
            <Button
              className={classes.button}
              theme="primary-light"
              size="middle"
              onClick={handleShowDotsModal}
            >
              {t('Remove')}
            </Button>
          </div>
        </div>
      </ModalMobile>

      {
        <ModalMobile
          title=""
          isOpen={isShowDotsMenu}
          onClose={handleCloseDotsModal}
          isBasicHeader={false}
          isOnCenter
          crossIcon={false}
          noPadding={true}
        >
          <div className={clsx(classes.inner, classes.innerDelete)}>
            <div className={classes.headerWrapper}>
              <h3 className={classes.titleRemove}>
              {value.full_name ? (
                t('Remove {{ name }} from Account?', { name: value.full_name })
                ) : (
                   <>
                 {t('Remove')} <a className={classes.emailLink}>{value.email}</a> {t('from Account?')}
                    </>
                 )}
              </h3>
              <p className={classes['title-description-remove']}>
                {t(
                  'Employee will no longer have access to Account. This action cannot be undone'
                )}
              </p>
            </div>

            <div className={classes.buttons}>
              <Button
                className={classes.button}
                theme="primary"
                size="middle"
                onClick={(e) => {
                  e.stopPropagation();
                  handleCloseDotsModal();
                }}
              >
                {t('Cancel')}
              </Button>
              <Button
                className={classes.button}
                theme="primary-light"
                size="middle"
                onClick={async (e) => {
                  e.stopPropagation();
                  await handleDeletePermissions(value.id, value.email);
                  handleClose();
                }}
              >
                {t('Continue')}
              </Button>
            </div>
          </div>
        </ModalMobile>
      }
    </>
  );
};

export default MenuCell;
