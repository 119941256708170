import { OptionsType } from '@type/global';
import { useEffect } from 'react';
import {
  SelectedLocation,
  SelectedProducts,
  SetSelectedLocation,
  SetSelectedProducts,
} from './StepTwo.props';

export const useStepTwo = (
  productsOptions: OptionsType[],
  setSelectedProducts: SetSelectedProducts,
  locationOptions: OptionsType[],
  setSelectedLocation: SetSelectedLocation,
  isValid: boolean,
  recaptcha: string,
  selectedProducts: SelectedProducts[],
  setIsStepTwoValid: (val: boolean) => void,
  selectedLocation?: SelectedLocation
) => {
  const isDisableCaptcha = process.env.REACT_APP_DISABLE_RECAPCHA === 'true';
  const callbacks = {
    onSelectProduct: (id: number) => {
      const selectedProduct = productsOptions.find(
        (product) => product.value === id
      );
      if (selectedProduct) {
        setSelectedProducts((prevSelected) => {
          return [
            ...prevSelected,
            {
              id: selectedProduct.value,
              name: selectedProduct.label,
            },
          ];
        });
      }
    },
    onSelectLocation: (id: number) => {
      const selectedLocation = locationOptions.find(
        (location) => location.value === id
      );
      if (selectedLocation) {
        setSelectedLocation({
          id: selectedLocation.value,
          name: selectedLocation.label,
        });
      }
    },
    onDeleteSelectedProduct: (id: number) => {
      setSelectedProducts((prevSelected) =>
        prevSelected.filter((product) => product.id !== id)
      );
    },
  };

  useEffect(() => {
    if (isDisableCaptcha) {
      if (selectedProducts.length && selectedLocation?.id && isValid) {
        setIsStepTwoValid(true);
      } else if (
        isValid &&
        recaptcha !== '' &&
        selectedProducts.length &&
        selectedLocation?.id
      ) {
        setIsStepTwoValid(true);
      } else {
        setIsStepTwoValid(false);
      }
    } else if (
      isValid &&
      recaptcha !== '' &&
      selectedProducts.length &&
      selectedLocation?.id
    ) {
      setIsStepTwoValid(true);
    } else {
      setIsStepTwoValid(false);
    }
  }, [isValid, recaptcha, selectedProducts.length, selectedLocation?.id]);

  return { callbacks, isDisableCaptcha };
};
