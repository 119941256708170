import desktop from 'assets/img/Login/desktop.jpg';
import es_desktop from 'assets/img/Login/es_desktop.png';
import es_mobile from 'assets/img/Login/es_mobile.png';
import mobile from 'assets/img/Login/mobile.png';
import wordLogo from 'assets/img/Login/word-logo.png';
import logo from 'assets/img/logo.png';
import clsx from 'clsx';
import { Button } from 'components/shared/Button';
import { EyeIcon } from 'components/shared/Icons/EyeIcon';
import { InfoIcon } from 'components/shared/Icons/InfoIcon';
import { Input } from 'components/shared/Input';
import { Link } from 'components/shared/Link';
import { TitleAndDescription } from 'components/shared/TitleAndDescription/TitleAndDescription';
import { TooltipContainer } from 'components/shared/Tooltip/TooltipContainer';
import { useWindowWidth } from 'hooks/useWindowWidth';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { Controller } from 'react-hook-form';
import { NavLink, useLocation } from 'react-router-dom';
import classes from './Login.module.scss';
import { useLogin } from './useLogin';
import { MultiselectComponent } from 'components/shared/MultiselectComponent';
import { languages } from 'constants/languages';

export const LoginContainer = observer(() => {
  const {
    t,
    i18n,
    errors,
    control,
    handleSubmit,
    onSubmit,
    disableSubmit,
    recaptchaRef,
    setRecaptcha,
    setEmail,
    setPassword,
    defaultLanguage,
    changeLanguage,
    isDisableCaptcha,
    invite,
  } = useLogin();
  const [showPassword, setShowPassword] = useState(false);
  const { isMediaTablet } = useWindowWidth();
  const location = useLocation();

  useEffect(() => {
    const source = new URLSearchParams(location.search)?.get('utm_source');
    const existSource = localStorage.getItem('utm_mark');

    if (source && !existSource) {
      localStorage.setItem('utm_mark', source);
    }
  }, []);

  return (
    <>
      <TitleAndDescription pageName="Login" />

      <div className={classes.container}>
        <NavLink to={`${process.env.REACT_APP_MAIN_SITE_URL}`}>
          <img src={logo} alt="" className={classes.logo} />
        </NavLink>

        <div className={classes.left}>
          <div className={classes['left-content']}>
            <span className={classes['left-content--title']}>
              {t('KIT Global — world-class digital solutions')}
            </span>
            <span className={classes['left-content--text']}>
              {t(
                'We know how to improve your business through internet marketing with no limits'
              )}
            </span>
          </div>
          <div className={classes['left-images']}>
            <img
              src={i18n.language === 'es' ? es_desktop : desktop}
              alt=""
              className={classes.image}
            />
            <img
              src={i18n.language === 'es' ? es_mobile : mobile}
              alt=""
              className={classes['image-ontop']}
            />
          </div>
        </div>

        <div className={classes.right}>
            {invite && (
              <div className={classes.invitation}>
                {t('Follow invitation link')}
              </div>
            )}
          <div className={classes.lang}>
            <MultiselectComponent
              data={languages}
              langSelect={true}
              defaultSelected={defaultLanguage}
              setMultiselect={changeLanguage}
              className={classes.multiselect}
            />
          </div>
          <div className={classes.title}>
            {t('Login to')}{' '}
            <img src={wordLogo} alt="" className={classes['word-logo']} />
          </div>
          <div className={classes.text}>
            {!invite && (
            <span>
              {t('Don’t have an account')}?
              <Link className={classes.link} href="/register">
                {t('Sign up')}
              </Link>
            </span>
            )}
          </div>
          {errors.email && (
            <div className={classes['mobile-error']}>
              {t(errors.email.message || '')}
            </div>
          )}

          <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
            <div className={classes.fields}>
              <div className={classes['input-field']}>
                <div className={classes['item-label']}>{t('Work email')}</div>
                <Controller
                  name="email"
                  control={control}
                  render={({ field }: { field: any }) => (
                    <Input
                      type={'text'}
                      placeholder={t('Enter your work email')}
                      value={field.value}
                      onChange={(e) => {
                        field.onChange(e);
                        setEmail(e);
                      }}
                    />
                  )}
                />
              </div>
              <div className={classes['input-field']}>
                <div className={classes['item-label']}>{t('Password')}</div>
                <Controller
                  name="password"
                  control={control}
                  render={({ field }: { field: any }) => (
                    <Input
                      type={showPassword ? 'text' : 'password'}
                      placeholder={t('Enter password')}
                      value={field.value}
                      onChange={(e) => {
                        field.onChange(e);
                        setPassword(e);
                      }}
                    />
                  )}
                />
                <div
                  role="button"
                  tabIndex={-1}
                  onClick={() => setShowPassword(!showPassword)}
                  onKeyDown={() => {}}
                  className={classes['show-password']}
                >
                  <EyeIcon width="18px" height="14px" />
                </div>

                {errors.password && !isMediaTablet && (
                  <TooltipContainer
                    text={t(errors.password.message || '')}
                    customClasses={'kit-ui-block'}
                    position={'top'}
                    className={classes.error}
                  >
                    <InfoIcon fill="#F5222D" width="18px" height="18px" />
                  </TooltipContainer>
                )}

                <Link
                  className={clsx(classes.link, classes.password)}
                  href="/restore"
                >
                  {t('I forgot my password')}
                </Link>
              </div>
            </div>

            {errors.password && isMediaTablet && (
              <div className={classes['mobile-error']}>
                {errors.password?.message}
              </div>
            )}

            {isDisableCaptcha ? null : (
              <div className={classes.captcha}>
                <ReCAPTCHA
                  ref={recaptchaRef}
                  sitekey={`${process.env.REACT_APP_GOOGLE_RECAPTHA_KEY}`}
                  onChange={(e) => e && setRecaptcha(e)}
                  hl="en"
                  size="normal"
                />
              </div>
            )}

            <Button
              className={clsx(
                classes.button,
                classes['submit-button'],
                isDisableCaptcha && classes.disabledCaptcha
              )}
              theme="primary"
              disabled={disableSubmit}
            >
              {t('Log in')}
            </Button>
          </form>
        </div>
      </div>
    </>
  );
});
