import api from './http';
import { TFinancesResponse } from '@type/finance';

export default class Finances {
  static async getFinances(
    partnerId: number,
    params?: {
      all?: boolean;
      page?: number;
      page_size?: number;
    }
  ): Promise<TFinancesResponse> {
    const response = await api<TFinancesResponse>({
      url: `/api/v1/partners/${partnerId}/finances/`,
      method: 'get',
      params,
    });

    return response.data;
  }
}
