import { makeAutoObservable, runInAction } from 'mobx';
import { AxiosError } from 'axios';
import { IStatus } from '@type/api';
import StatusFactory from 'tools/status';
import { TFinancesRequest, TFinancesResponse } from '@type/finance';
import Finances from 'services/finances';
import { partnerStore } from './partnerStore';
import { userStore } from './user-store';

export class FinancesStore {
  finances: TFinancesResponse | null = null;
  loadFinancesStatus: IStatus<TFinancesResponse | null> =
    StatusFactory.default();

  constructor() {
    makeAutoObservable(this);
  }

  public resetLoadFinancesStatus(): void {
    this.loadFinancesStatus = StatusFactory.default();
  }

  public async loadFinances(params?: TFinancesRequest, onError?: () => void) {
    this.loadFinancesStatus = StatusFactory.loading();
    const partnerId = userStore.partners?.results[0]?.id;

    if (partnerId) {
      try {
        const response = await Finances.getFinances(partnerId, params);

        runInAction(() => {
          this.finances = response;
          this.loadFinancesStatus = StatusFactory.success(response);
        });
      } catch (error) {
        onError?.();
        runInAction(() => {
          this.loadFinancesStatus = StatusFactory.failed(error as AxiosError);
        });
      }
    }
  }

  clearStore() {
    this.finances = null;
  }
}

export const financesStore = new FinancesStore();
