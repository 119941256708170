import { FC, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import React from 'react';
import classes from './SearchResults.module.scss';
import { Link } from 'components/shared/Link';
import { PurpleArrowLeftIcon } from 'components/shared/Icons/PurpleArrowLeftIcon';
import { TitleAndDescription } from 'components/shared/TitleAndDescription/TitleAndDescription';
import { TabFilter } from 'components/shared/TabFilter';
import { Button } from 'components/shared/Button';
import { PageLoader } from 'components/shared/PageLoader';
import { Status, FILTERS_LABELS, FILTERS } from 'constants/enum';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { ShowNotification } from 'tools/showNotification';
import api from 'services/http';
import Search from 'services/search';
import clsx from 'clsx';
import { PartnerCard } from 'containers/PartnerContainer/Card';
import { priceTextContent } from 'components/shared/PriceText';
import { getLangName } from 'tools/utils';
import { ProjectType, SolutionType } from 'containers/Projects/types';
import ProjectsTable from 'containers/Projects/ProjectsTable';
import { useWindowWidth } from 'hooks/useWindowWidth';
import ProjectsList from 'containers/Projects/ProjectsList';
import UiIcon from '../../components/shared/Icon';
import { useCommonStore } from 'contexts/CommonContext';
import { ResultTab } from './types';
import { useUserStore } from '../../contexts/UserContext';
import { Pagination } from '../../components/shared/Pagination';
import { queryParamsHandler } from '../PartnerContainer/utils';
import { PARTNER_COUNTER } from '../PartnerContainer/usePartnerContainer';
import { FilterTypes } from '@type/partner';

export const SearchResultsContainer: FC = observer(({}) => {
  const { t, i18n } = useTranslation();
  const { setIsSearchResults } = useCommonStore();
  const { isMediaTablet } = useWindowWidth();
  const { hash, search } = useLocation() || {};
  const parsedHash = queryString.parse(hash);
  const fromPage = (parsedHash?.from as string) || '/solutions';
  const { currency } = useUserStore()?.user;

  const searchTerm = (parsedHash?.search as string) || '';
  const [solutions, setSolutions] = useState<any>(null);
  const [projects, setProjects] = useState<any>(null);
  const [finances, setFinances] = useState<any>(null);
  const [allProjectsLength, setAllProjectsLength] = useState(0);
  const [currentPageSolution, setCurrentPageSolution] = useState(1);

  const tabOptions = [
    {
      id: 1,
      name_key: 'solutions',
      name: t('Solutions'),
      count: 0,
    },
    {
      id: 2,
      name_key: 'projects',
      name: t('Projects'),
      count: 0,
    },
    {
      id: 3,
      name_key: 'finances',
      name: t('Finances'),
      count: 0,
    },
  ];

  const [tabs, setTabs] = useState<ResultTab[]>(tabOptions);
  const [isLoading, setisLoading] = useState(true);
  const [activeTab, setActiveTab] = useState<ResultTab>(tabs?.[0]);

  const switchTabs = () => {
    switch (fromPage) {
      case 'solutions':
        return tabs[0];
      case 'projects':
        return tabs[1];
      case 'finances':
        return tabs[2];
      default:
        return tabs[0];
    }
  };

  useEffect(() => {
    if (search?.includes('projects')) {
      setActiveTab(tabs[1]);
    } else {
      const currentTab = switchTabs();
      setActiveTab(currentTab);
    }
  }, [fromPage, search, tabs]);

  const handlePaginate = async (type?: string) => {
    try {
      if (type === 'projects') {
        const response = (await api.get(projects?.next))?.data;
        setProjects((prevProjects: any) => ({
          count: response.count,
          next: response.next,
          previous: response.previous,
          results: [...prevProjects.results, ...response.results],
        }));
      }
    } catch (error) {
      ShowNotification({
        type: 'error',
        children: t('An error occurred. Please try again later'),
      });
    }
  };

  const fetchData = async (limit: number = 3, offset: number = 1) => {
    setisLoading(true);
    try {
      const searchTerm = (queryString.parse(hash)?.search as string) || '';
      const responseSolutions = await Search.getSearchResults(
        'solutions',
        searchTerm,
        limit,
        offset
      );
      const responseProjects = await Search.getSearchResults(
        'projects',
        searchTerm
      );
      setSolutions(responseSolutions?.data);
      setProjects(responseProjects?.data);
      setTabs((prevTabs: any) => {
        const updatedTabs = [...prevTabs];
        const solutionsIndex = updatedTabs.findIndex(
          (tab) => tab.name_key === 'solutions'
        );
        const projectsIndex = updatedTabs.findIndex(
          (tab) => tab.name_key === 'projects'
        );
        updatedTabs[solutionsIndex].count = responseSolutions?.data?.count;
        updatedTabs[projectsIndex].count = responseProjects?.data?.count;
        return updatedTabs;
      });
    } catch (error) {
      ShowNotification({
        type: 'error',
        children: 'An error occurred. Please try again later',
      });
    }
    setisLoading(false);
  };

  const getStatus = (status: Status) => {
    switch (status) {
      case Status.active:
        return FILTERS_LABELS.ACTIVE;
      case Status.reviewed:
        return FILTERS_LABELS.IN_REVIEW;
      case Status.archived:
        return FILTERS_LABELS.ARCHIVED;
      case Status.rejected:
        return FILTERS_LABELS.REJECTED;
      case Status.moderated:
        return FILTERS_LABELS.IN_REVIEW;
      default:
        return FILTERS_LABELS.ALL;
    }
  };

  const isEmpty =
    (activeTab.name_key === 'solutions' && !solutions?.results?.length) ||
    (activeTab.name_key === 'projects' && !projects?.results?.length) ||
    (activeTab.name_key === 'finances' && !finances?.results?.length);

  useEffect(() => {
    fetchData(3, currentPageSolution);
  }, [window.location.href, currentPageSolution]);

  useEffect(() => {
    setIsSearchResults(isEmpty);
  }, [solutions?.results, projects?.results, activeTab.name_key]);

  const itemsPerPage = 3;

  const items = Array.from({ length: solutions?.count }, (_, i) => i + 1);

  const totalPages = Math.ceil(items.length / itemsPerPage);

  const paginatedChunks = [];
  for (let i = 0; i < totalPages; i++) {
    const start = i * itemsPerPage;
    const end = start + itemsPerPage;
    paginatedChunks.push(items.slice(start, end));
  }

  const cardInfo = useMemo(
    () =>
      solutions?.results?.map((singleService: any) => {
        const status: Status = singleService.status;
        const statusLabel = getStatus(status);
        
        return {
          status: statusLabel,
          name: getLangName(singleService, 'name', i18n),
          id: singleService?.id,
          currency: currency,
          source: {
            name: getLangName(singleService?.product, 'name', i18n),
            link: `/solutions/${singleService?.slug}`,
          },
          description: getLangName(singleService, 'description', i18n),
          full_description: getLangName(singleService, 'description', i18n),
          short_description: getLangName(
            singleService,
            'short_description',
            i18n
          ),
          period_comment: getLangName(singleService, 'period_comment', i18n),
          product: singleService?.product,
          stages: singleService?.stages,
          cases: singleService?.case,
          company_size: singleService?.company_size,
          business_type: singleService?.business_type,
          goals: singleService?.goals,
          cost: singleService?.cost_with_discounts,
          isFree: singleService?.is_free,
          price_per: singleService?.price_per,
          slug: singleService?.slug,
          data: {
            impressions: {
              name: t('Impressions'),
              description: t('Compared to last week'),
              count: singleService.impressions_weekly,
              percentage:
                singleService?.service_analytics?.[0]
                  ?.impressions_weekly_diff || '0%',
            },
            favorites: {
              name: t('Added to favorites'),
              description: t('Compared to last week'),
              count: singleService.added_to_favorites_weekly,
              percentage:
                singleService?.service_analytics?.[0]
                  ?.added_to_favorites_weekly_diff || '0%',
            },
            cart: {
              name: t('Added to cart'),
              description: t('Compared to last week'),
              count: singleService.added_to_cart_weekly,
              percentage:
                singleService?.service_analytics?.[0]
                  ?.added_to_cart_weekly_diff || '0%',
            },
            price: {
              name: t('Price'),
              discountCost: singleService?.is_free
                ? 0
                : singleService?.cost_with_discounts,
              cost: singleService?.is_free ? 0 : singleService.cost,
              description: '',
              period: singleService.cost
                ? priceTextContent(singleService, t, false, false, true)
                : '',
            },
          },
        };
      }),
    [solutions, i18n.language]
  );

  const projectInfo: SolutionType[] = useMemo(() => {
    setAllProjectsLength(0);
    return projects?.results?.map((solution: any) => {
      setAllProjectsLength(
        (prevProjects) => prevProjects + solution?.projects?.length
      );
      return {
        id: solution?.id,
        solution: {
          id: solution?.id,
          name: getLangName(solution, 'name', i18n),
          totalCost: solution?.total_cost,
          currency: solution?.total_cost_currency,
          statusCount: solution?.project_status_count,
        },
        projects: solution?.projects?.map(
          (project: any) =>
            ({
              id: project?.number,
              company: project?.company_name,
              source: project?.source,
              cost: project?.cost[0]?.cost,
              currency: project?.currency,
              type: project?.cost[0]?.type,
              status: project?.status,
              serial: 0,
              solutionId: solution?.id,
              projectId: project.id,
            }) as ProjectType
        ),
      };
    });
  }, [projects, i18n.language]);

  const goToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  if (isLoading) {
    return <PageLoader />;
  }

  const emptyState = (
    <div className={classes.emptyState}>
      <UiIcon
        name="EmptyIcon"
        additionalClassName={classes['emptyState__icon']}
      />
      <span className={classes['emptyState__text']}>
        {t('No matches for your search')}
      </span>
      <span className={classes['emptyState__description']}>
        {t('Make sure all words are spelled correctly')}
      </span>
    </div>
  );

  const renderProducts = () => {
    if (isEmpty) {
      return emptyState;
    } else {
      return (
        <div
          className={clsx(
            classes.list,
            activeTab?.id === 2 && classes.projectList
          )}
        >
          {activeTab.name_key === 'solutions' &&
            cardInfo?.map((service: any) => (
              <PartnerCard key={service?.id} project={service} />
            ))}
          {activeTab.name_key === 'projects' && (
            <div className={classes['projects-wrapper']}>
              {isMediaTablet ? (
                <ProjectsList data={projectInfo.slice(0, 5)} />
              ) : (
                <ProjectsTable data={projectInfo} />
              )}
            </div>
          )}
        </div>
      );
    }
  };

  return (
    <>
      <TitleAndDescription pageName="SearchResults" />
      <div className={classes.wrapper}>
        <Link className={classes.back} href={decodeURIComponent(fromPage)}>
          <PurpleArrowLeftIcon /> {t('Back')}
        </Link>
        <div className={classes.container}>
          <div className={classes.title}>
            {`${t('Search results for')} `}
            <span className={classes['search-term']}>{`«${searchTerm}»`}</span>
          </div>
          <TabFilter
            tabArray={tabs}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
          />
          {renderProducts()}
          <div className={classes.pagination}>
            {activeTab.id === 1 && (solutions?.next || solutions?.previous) && (
              <Pagination
                currentPage={currentPageSolution}
                totalPages={paginatedChunks?.length}
                setPageNumber={(page: number) => {
                  setCurrentPageSolution(page);
                  handlePaginate(activeTab?.name_key);

                  goToTop();
                }}
                nextPage={() =>
                  currentPageSolution > 0 &&
                  currentPageSolution < 8 &&
                  setCurrentPageSolution(currentPageSolution + 1)
                }
                prevPage={() =>
                  currentPageSolution !== 0 &&
                  setCurrentPageSolution(currentPageSolution - 1)
                }
              />
            )}
          </div>
          {/*{activeTab.id === 2 && projects?.next && activeTab.count > 3 && (
            <div className={classes.pagination}>
              <Button
                onClick={() => handlePaginate(activeTab?.name_key)}
                theme="default"
                className={classes.button}
              >
                {t('See more')}
              </Button>
            </div>
          )}*/}
        </div>
      </div>
    </>
  );
});
