import { FC, useEffect, useState, useRef } from 'react';
import _ from 'lodash';
import clsx from 'classnames';
import { Input } from 'components/shared/Input';
import classes from './MultiselectComponent.module.scss';
import { ArrowDownIcon } from 'components/shared/Icons/ArrowDownIcon';
import { CheckIcon } from 'components/shared/Icons/CheckIcon';
import { useEventListener } from 'hooks/useEventListener';
import { useTranslation } from 'react-i18next';
import React from 'react';
import i18n from 'i18n/i18n';

interface Props {
  data: Option[];
  defaultSelected: any;
  placeholder?: string;
  search?: boolean;
  setMultiselect: (val: any) => void;
  langSelect?: boolean;
  textTheme?: boolean;
  sortById?: boolean;
  isCompanySize?: boolean;
  isFieldEmpty?: boolean;
  className?: string;
  disabled?: boolean;
  innerLabel?: string;
}

interface Option {
  id: number | string;
  name: string;
  description?: string;
  char_code?: string;
  size?: number;
}

export const MultiselectComponent: FC<Props> = ({
  data,
  defaultSelected,
  placeholder,
  search = false,
  setMultiselect,
  langSelect,
  textTheme,
  isCompanySize,
  isFieldEmpty,
  className,
  disabled,
  innerLabel,
  sortById,
}) => {
  const [open, setOpen] = useState(false);
  const [currentValue, setCurrentValue] = useState<any>();
  const [sourcesToShow, setSourcesToShow] = useState<any>();
  const ref = useRef<any>(null);
  const [inputValue, setInputValue] = useState('');

  const { t } = useTranslation();

  useEffect(() => {
    if (data) {
      if (isCompanySize) {
        const companySizes = _.sortBy(data, ['size']);
        setSourcesToShow(companySizes);
      } else if (sortById) {
        const sortedSources = _.sortBy(data, (source) => source.id);
        setSourcesToShow(sortedSources);
      } else {
        const uniqueSources = _.uniqBy(_.filter(data, 'name'), 'id');
        const sortedSources = _.sortBy(uniqueSources, (source) =>
          source.name.toLowerCase()
        );
        setSourcesToShow(sortedSources);
      }
    }
  }, [defaultSelected, data, isCompanySize]);

  function filterElements(inputValue: string) {
    const filtered = data.filter((elem: { name: string }) =>
      elem.name.toLowerCase().includes(inputValue.toLowerCase())
    );
    setSourcesToShow(filtered.length ? filtered : []);
  }

  const handleClick = (e: any) => {
    if (ref.current && !ref.current?.contains(e.target)) {
      setOpen(false);
    }
  };

  useEventListener('click', handleClick);

  function getDataAttribute() {
    switch (placeholder) {
      case 'lang':
        return 'name';
      case 'country':
        return 'name';
      case 'currency':
        return 'char_code';
      default:
        return 'name';
    }
  }

  function getPlacehoderMultiselect() {
    switch (placeholder) {
      case 'lang':
        return t('Choose your language');
      case 'country':
        return t('Choose your Country');
      case 'currency':
        return t('Choose Currency');
      default:
        return '';
    }
  }

  function getPlacehoderDropdown() {
    switch (placeholder) {
      case 'lang':
        return t('Enter language');
      case 'country':
        return t('Enter Country');
      case 'currency':
        return t('Enter Currency');
      default:
        return t('Enter element');
    }
  }

  const firstUp = (str: string) => {
    if (!str) return str;

    return str[0].toUpperCase() + str.slice(1);
  };

  useEffect(() => {
    if (defaultSelected) {
      const item = data?.find((elem) => elem.id === defaultSelected);
      setCurrentValue(item);
      setMultiselect(item);
    }
  }, [defaultSelected, inputValue, i18n.language]);

  useEffect(() => {
    if (inputValue) {
      filterElements(inputValue);
    } else {
      setSourcesToShow(data);
    }
  }, [inputValue]);

  function getDefaultValueMultiselect() {
    if (langSelect) {
      return firstUp(currentValue?.lang_code);
    }

    if (!currentValue) {
      return (
        data?.find((elem) => elem.id === defaultSelected)?.[
          getDataAttribute()
        ] || ''
      );
    } else {
      if (currentValue['description']) {
        return `${currentValue[getDataAttribute()]} (${
          currentValue['description']
        })`;
      }
      return currentValue[getDataAttribute()];
    }
  }

  return (
    <div
      ref={ref}
      className={clsx(
        classes.wrapper,
        langSelect && classes.selectLang,
        textTheme && classes.textTheme,
        className
      )}
    >
      <div
        onClick={() => {
          setOpen(!open);
        }}
        className={clsx(
          classes['main-input'],
          open && classes.dropActive,
          isFieldEmpty && classes.error,
          disabled && classes.disabled
        )}
      >
        {textTheme ? (
          <span className={classes['main-input__text']}>
            {getDefaultValueMultiselect()}
          </span>
        ) : (
          <Input
            className={classes.drop}
            readOnly={true}
            placeholder={getPlacehoderMultiselect()}
            value={getDefaultValueMultiselect()}
          />
        )}
        {!disabled && (
          <div className={clsx(classes.arrow, open && classes.arrowActive)}>
            <ArrowDownIcon />
          </div>
        )}
      </div>
      {isFieldEmpty && (
        <span className={classes.errorMessage}>
          Fill in the fields to better understand your business.
        </span>
      )}
      {open && data && (
        <div className={classes.dropdown}>
          {search && (
            <div className={classes['enter-source']}>
              <Input
                innerLabel={innerLabel}
                value={inputValue || ''}
                onChange={(e) => setInputValue(e)}
                placeholder={getPlacehoderDropdown()}
                className={classes.input}
              />
            </div>
          )}
          <div className={classes.list}>
            {sourcesToShow &&
              sourcesToShow?.map((item: any, index: number) => (
                <div
                  key={`${item.id}${index}`}
                  onClick={() => {
                    setCurrentValue(item);
                    setOpen(false);
                    setMultiselect(item);
                  }}
                  className={clsx(
                    classes.item,
                    (item?.name === currentValue?.name ||
                      item.id === currentValue?.id) &&
                      classes.active
                  )}
                >
                  {item[getDataAttribute()]} <span>{item['description']}</span>
                  {item?.id === currentValue?.id && (
                    <CheckIcon fill="#8362F3" />
                  )}
                </div>
              ))}
          </div>
        </div>
      )}
    </div>
  );
};
