import React, { FC } from 'react';
import classes from '../Cases.module.scss';
import casePhone from 'assets/img/Partner/casePhone.png';
import casePhoneRu from 'assets/img/Partner/casePhoneRu.png';
import { getLangName } from 'tools/utils';
import { DevicePlatform } from '@type/common';
import { Case, InternalCaseKPI } from '@type/partner';
import { AMOUNT_TYPE } from 'constants/enum';
import { formatCasesForLocale } from '../formattedCases';
import { SingleCaseProps } from './SingleCase.props';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { useCommonStore } from 'contexts/CommonContext';
import { RUSSIAN_LOCALE } from 'utils';
import clsx from 'clsx';
import { Checkbox } from 'components/shared/Checkbox/Checkbox';
import { usePartnerStore } from 'contexts/PartnerContext';

const SingleCase: FC<SingleCaseProps> = observer(
  ({ singleCase, existingCaseBlock, handleSelect, withScroll, isPreview }) => {
    const { platforms, dictionaries } = useCommonStore();
    const { selectedExistingCases } = usePartnerStore();
    const intersection = (currentCase: Case) =>
      _.intersectionBy(platforms, currentCase?.platforms, 'id');
    const { i18n } = useTranslation();

    const caseImage =
      singleCase.image || (RUSSIAN_LOCALE ? casePhoneRu : casePhone);
    const businessTypesDict = dictionaries?.business_types;

    const businessType = getLangName(
      businessTypesDict?.find(
        (item) =>
          item?.id === (singleCase?.caseBusinessType as unknown as number)
      ),
      'name',
      i18n
    );

    const isChecked = selectedExistingCases?.some(
      (selectedCase: Case) => selectedCase?.id === singleCase?.id
    );

    return (
      <div
        className={clsx(
          classes.caseBlock,
          existingCaseBlock && classes.withCheckbox,
          withScroll && classes.caseBlock__withScroll
        )}
      >
        {existingCaseBlock && (
          <Checkbox
            checked={isChecked}
            className={classes.caseCheckbox}
            onChange={() => {
              if (handleSelect) {
                handleSelect(singleCase as Case);
              }
            }}
          />
        )}
        <div className={classes.case}>
          <div
            className={clsx(
              classes['image-wrapper'],
              existingCaseBlock && classes.existingCaseBlock
            )}
          >
            <img className={classes.image} src={caseImage} alt="" />
          </div>

          <div className={clsx(classes.info, classes.existingCaseBlock)}>
            <div className={classes.head}>
              <div className={classes.type}>{businessType}</div>

              <div className={classes.tags}>
                {intersection(singleCase as Case)
                  ?.slice(0, 3)
                  ?.map((tag: DevicePlatform) => {
                    if (tag?.image) {
                      return (
                        <div className={classes.tag_img} key={tag?.id}>
                          <img
                            className={classes.platform}
                            src={tag?.image?.toLowerCase()}
                            alt=""
                          />
                        </div>
                      );
                    } else {
                      return (
                        <div key={tag?.id} className={classes.tag}>
                          {getLangName(tag, 'name', i18n)}
                        </div>
                      );
                    }
                  })}
              </div>
            </div>

            <div className={classes.body}>
              <div className={classes.name}>
                {getLangName(singleCase, 'caseName', i18n)}
              </div>
            </div>

            <div
              className={clsx(
                classes.kpises,
                isPreview && classes.kpises_preview
              )}
            >
              {singleCase?.kpi?.map((kpi: InternalCaseKPI, index) => (
                <div className={classes.kpi} key={index}>
                  <div className={classes.description}>{kpi?.name}</div>

                  <div className={classes.amount}>
                    {
                      AMOUNT_TYPE[
                        kpi?.valueType as unknown as keyof typeof AMOUNT_TYPE
                      ]
                    }
                    {kpi?.value &&
                      kpi?.unit &&
                      formatCasesForLocale(kpi?.value, kpi?.unit, 2)}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  }
);

export default SingleCase;
