import { AxiosResponse } from 'axios';
import api from './http';
import { GetSolutionProjectsResponse, Project } from '@type/projects';
import { ShowNotification } from '../tools/showNotification';
import i18next from 'i18next';

export default class ProjectsService {
  static async getSolutionProjects(
    partnerId: number,
    params: string = '',
    nextPage?: string,
    prev?: string
  ): Promise<AxiosResponse<GetSolutionProjectsResponse>> {
    if (nextPage) {
      return api.get<GetSolutionProjectsResponse>(nextPage);
    }
    if (prev) {
      return api.get<GetSolutionProjectsResponse>(prev);
    }
    const paramsData = params ? `?${params}` : '';
    return api.get<GetSolutionProjectsResponse>(
      `/api/v1/partners/${partnerId}/projects${paramsData}`
    );
  }

  static async getProject(id: number) {
    return api.get<Project>(`/api/v1/partners/project/${id}`);
  }

  static async handleDownloadFile(fileUrl: string, fileName: string) {
    try {
      const response = await api.get(fileUrl, {
        responseType: 'blob',
      });

      const contentDisposition = response?.headers['content-disposition'];

      const match = contentDisposition.match(/filename="?([^"]+)"?/);

      if (match && match[1]) {
        const fileName = match[1];

        if (response.status === 200) {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `${fileName}` || 'file');
          document.body.appendChild(link);
          link.click();
          if (link.parentNode) {
            link.parentNode.removeChild(link);
          }
          window.URL.revokeObjectURL(url);
        } else {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
      }
    } catch (error: any) {
      if (error?.response?.status === 404) {
        ShowNotification({
          type: 'error',
          children: i18next.t('File not found'),
        });
      } else {
        ShowNotification({
          type: 'error',
          children: error.message,
        });
      }
    }
  }
}
